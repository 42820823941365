/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  MixedWidget1,
  ChartsWidget6,
  ChartsWidget1,
  ChartsWidget2,
} from '../../../_metronic/partials/widgets'
import GraphicsComponentSc from '../../modules/apps/graphicSc-management/graphic/GraphicsComponentSc';
import GraphicsComponentScCon from '../../modules/apps/graphicScCon-management/graphic/GraphicsComponentScCon';

import { useAuth } from '../../modules/auth/core/Auth'

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const { currentUser } = useAuth()
  console.log('usuario actual', currentUser)

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      {currentUser?.profile_id === 1 ? (
        <GraphicsComponentSc />
      ) : (
        <GraphicsComponentScCon />
      )}
    </>
  )
}

export {DashboardWrapper}
